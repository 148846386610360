import { ListGroup } from 'react-bootstrap';
import { UserLiveInterface } from 'zero-chords-shared';
import LiveUserListItemComponent from './LiveUserListItem';

function LiveUserListComponent({ users, handleDeleteUser, handleUserClick }: { users: UserLiveInterface[]; handleDeleteUser?: (userId: string) => void; handleUserClick?: (userId: string) => void }) {
  return (
    <>
      <ListGroup as="ol" className="cardHolder">
        {users.map((user: UserLiveInterface, idx: number) => (
          <LiveUserListItemComponent user={user} handleDeleteUser={handleDeleteUser} handleUserClick={handleUserClick} />
        ))}
      </ListGroup>
    </>
  );
}

export default LiveUserListComponent;
