import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SchemaInterface, SongInterface } from 'zero-chords-shared';
import { fetchSchema } from '../../services';
import { fetchDataState } from '../../utils';
import LoadingHolderComponent from '../loading/LoadingHolder';
import SongComponent from '../song/Song';

function SchemaSongViewerComponent() {
  const { schemaId, songId } = useParams();
  const [schema, setSchema] = useState(fetchDataState<SchemaInterface>({ data: null, loading: true }));
  const [displayedSong, setDisplayedSong] = useState<SongInterface | null>(null);
  const [currentNum, setCurrentNum] = useState<number | null>(null);

  useEffect(() => {
    if (schemaId) {
      getSchemas(schemaId);
    }
  }, []);

  const handlePrevNext = (value: number) => {
    const currSongIdx = schema.data?.songs.findIndex((song) => song.song.id === displayedSong?.id) ?? 0;
    const toTheRight = (): number => (currSongIdx < (schema.data?.songs.length ?? 0) - 1 ? currSongIdx + 1 : currSongIdx);
    const toTheLeft = (): number => (currSongIdx > 0 ? currSongIdx - 1 : currSongIdx);
    const newSongIdx = value > 0 ? toTheRight() : toTheLeft();
    const currSong = schema.data?.songs.at(newSongIdx)?.song;
    setDisplayedSong(currSong as SongInterface);
    setCurrentNum(newSongIdx + 1);
  };

  const getSchemas = (schemaId: string) =>
    fetchSchema(schemaId)
      .then((data) => {
        setSchema(fetchDataState({ data }));
        data.songs.find((song, idx) => {
          if (song.song.id === songId) {
            setDisplayedSong(song.song as SongInterface);
            setCurrentNum(idx + 1);
          }
        });
      })
      .catch(() => setSchema(fetchDataState<SchemaInterface>({ data: null, error: true })));

  return (
    <>
      {schema.data && displayedSong ? (
        <>
          <h6 className="mt-2">
            {schema.data?.title} {currentNum && `(${currentNum}/${schema.data.songs.length})`}
          </h6>
          <SongComponent loadedSong={displayedSong!} prevNextCallBack={handlePrevNext} />
        </>
      ) : (
        <LoadingHolderComponent fetchData={schema} />
      )}
    </>
  );
}

export default SchemaSongViewerComponent;
