import axios from 'axios';
import { HeaderEnum } from 'zero-chords-shared';
import { getUserInfoFromLocalStorage } from '../utils';
import { UserInfoInterface } from '../interfaces/ReducerInterface';

export const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL, headers: { [HeaderEnum.ACCEPT]: 'application/json', [HeaderEnum.API_KEY]: process.env.REACT_APP_API_KEY } });

axiosInstance.interceptors.request.use((config) => {
  const userInfo = getUserInfoFromLocalStorage() as UserInfoInterface;

  if (userInfo.userAuth?.accessToken) {
    // @ts-ignore
    config.headers = {
      ...config.headers,
      [HeaderEnum.AUTHORIZATION]: `Bearer ${userInfo.userAuth.accessToken}`,
      [HeaderEnum.USER_ID]: userInfo.userAuth.id,
    };
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      window.location.href = '/logoff';
    }
    throw error;
  }
);
