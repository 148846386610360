import { Button } from 'react-bootstrap';
import ModalMenuComponent from './ModalMenu';

function ConfirmationMenuComponent({ setShowConfirmation, message, callBackFn }: { setShowConfirmation: (value: boolean) => void; message: string; callBackFn: () => any }) {
  return (
    <ModalMenuComponent setDisplayMenu={setShowConfirmation}>
      <div className="m-4">
        <h3 className="textGray mt-5">¿Confirma que desea {message}?</h3>
        <div className="d-flex justify-content-center mt-5">
          <Button className="m-2" variant="danger" onClick={callBackFn}>
            Confirmar
          </Button>
          <Button className="m-2" variant="secondary" onClick={() => setShowConfirmation(false)}>
            Cancelar
          </Button>
        </div>
      </div>
    </ModalMenuComponent>
  );
}

export default ConfirmationMenuComponent;
