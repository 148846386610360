import { useSongs } from '../../hooks/SongHook';
import { SongInterface } from 'zero-chords-shared';
import FiltersComponent from '../filter/Filters';
import { useEffect, useState } from 'react';
import { fetchSongs } from '../../services/SongService';
import { fetchDataState } from '../../utils';
import SongListComponent from './list/SongList';
import LoadingHolderComponent from '../loading/LoadingHolder';

function SongSearchListComponent({
  title,
  showBackButton = true,
  onClickCallback,
  showSongListMenu = true,
}: {
  title?: string;
  showBackButton?: boolean;
  onClickCallback?: (songId: string) => void;
  showSongListMenu?: boolean;
}) {
  const { songFilters } = useSongs();
  const [displayedSongs, setDisplayedSongs] = useState(fetchDataState<SongInterface[]>({ data: null, loading: true }));

  const searchSongs = () =>
    fetchSongs(songFilters)
      .then((data) => setDisplayedSongs(fetchDataState<SongInterface[]>({ data })))
      .catch(() => setDisplayedSongs(fetchDataState<SongInterface[]>({ data: null, error: true })));

  useEffect(() => {
    searchSongs();
  }, []);

  return (
    <>
      <FiltersComponent searchSongs={searchSongs} />
      {(displayedSongs.data?.length ?? 0) > 0 ? (
        <SongListComponent
          songList={displayedSongs.data!.map((song, order) => {
            return { song, order };
          })}
          showBackButton={showBackButton}
          onClickCallback={onClickCallback}
          title={title}
          showSongListMenu={showSongListMenu}
        />
      ) : (
        <>
          <LoadingHolderComponent fetchData={displayedSongs} />
        </>
      )}
    </>
  );
}

export default SongSearchListComponent;
