import { useState } from 'react';
import animationStyle from './../../styles/Animation.module.css';
import * as Icon from 'react-bootstrap-icons';
import ConfirmationMenuComponent from '../menu/ConfirmationMenu';

function DeleteSongFromListComponent({ callBackFn, message }: { callBackFn: any; message: string }) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      <div className={`iconHolder  ${animationStyle['slideRightAnimation']}`} onClick={() => setShowConfirmation(true)}>
        <Icon.Trash3 size={'1.5em'} />
      </div>
      {showConfirmation && <ConfirmationMenuComponent setShowConfirmation={setShowConfirmation} callBackFn={callBackFn} message={message} />}
    </>
  );
}

export default DeleteSongFromListComponent;
