import { Accordion } from 'react-bootstrap';
import { SongInterface, TagInterface } from 'zero-chords-shared';
import SongCategoriesComponent from './SongCategories';

function SongCategoriesAccordion({ song, clickOnTag }: { song: SongInterface; clickOnTag?: (tag: TagInterface, status: boolean) => void }) {
  return (
    <Accordion className="m-1">
      <Accordion.Item eventKey="0">
        <Accordion.Header className="align-center">
          {clickOnTag ? 'Seleccionar categorías' : 'Categorías'} ({song.tags.length})
        </Accordion.Header>
        <Accordion.Body>
          <SongCategoriesComponent handleTagClick={clickOnTag} currentTags={song.tags} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default SongCategoriesAccordion;
