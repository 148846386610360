import { LIVE_ACTIONS_ENUM } from '../enums/Reducer.enums';
import { LiveReducerInterface } from '../interfaces/ReducerInterface';
import { setLiveInfoToLocalStorage } from '../utils';

export const LiveReducer = (state: LiveReducerInterface, action: { type: string; payload: LiveReducerInterface }) => {
  const { type } = action;
  let newState: LiveReducerInterface = { ...state };

  switch (type) {
    case LIVE_ACTIONS_ENUM.START_LIVE: {
      newState = { ...action.payload };
      break;
    }
    case LIVE_ACTIONS_ENUM.STOP_LIVE: {
      newState = { ...action.payload };
      break;
    }
  }

  setLiveInfoToLocalStorage(newState);
  return newState;
};
