import { TAG_LIST, TagInterface, TagTypeEnum } from 'zero-chords-shared';
import SongCategoryButton from './SongCategoryButton';
import { useMemo, useRef, useState } from 'react';

function SongCategoriesComponent({ handleTagClick, currentTags }: { handleTagClick?: (tag: TagInterface, status: boolean) => void; currentTags: string[] }) {
  const typeCount = useRef(-1);

  const getDefaultButtonStatus = (tagValue: string): boolean => {
    return currentTags.includes(tagValue);
  };

  const getCategoryButton = (tag: TagInterface, idx: number) => {
    return <SongCategoryButton tag={tag} handleClick={handleTagClick} defaultStatus={getDefaultButtonStatus(tag.value)} key={`song-category-${idx}`} />;
  };

  const isInCurrentTags = (tag: TagInterface) => currentTags.includes(tag.value);

  const categoriesSet = Object.values(TagTypeEnum).map((currCategory) => {
    return {
      type: currCategory,
      tags: TAG_LIST.filter((category) => category.tagType === currCategory).filter((category) => handleTagClick || isInCurrentTags(category)),
    };
  });

  const getTagsPerCategory = (category: { type: TagTypeEnum; tags: TagInterface[] }) => {
    const isSomeCategory = category.tags.length > 0;
    if (isSomeCategory) typeCount.current = typeCount.current + 1;
    return (
      <>
        {isSomeCategory && (
          <>
            {typeCount.current > 0 && <hr />}
            <h6>Categorías {category.type}</h6>
            <div className="d-flex flex-wrap ">{category.tags.map((tag, idx) => getCategoryButton(tag, idx))}</div>
          </>
        )}
      </>
    );
  };

  return <>{categoriesSet.map((category) => getTagsPerCategory(category))}</>;
}

export default SongCategoriesComponent;
