import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

export function SongListMenuComponent({}: {}) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="bottomButtonHolder">
        <Icon.PlusCircleFill size={`1.8em`} onClick={() => navigate('/song-new')} className="bottomButton" />
      </div>
    </div>
  );
}

export default SongListMenuComponent;
