import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchSong } from '../../services/SongService';
import { SongInterface } from 'zero-chords-shared';
import { useLive } from '../../hooks/LiveHook';
import { fetchDataState } from '../../utils';
import LoadingHolderComponent from '../loading/LoadingHolder';
import SongComponent from './Song';

function SongLoaderComponent({ fixedSongId }: { fixedSongId?: string }) {
  let { songId } = useParams();
  const { liveMode, updateLiveSong } = useLive();
  if (fixedSongId) {
    songId = fixedSongId;
  }
  const [song, setSong] = useState(fetchDataState<SongInterface>({ data: null, loading: true }));

  useEffect(() => {
    songId &&
      fetchSong(songId).then((data) => {
        setSong(fetchDataState({ data }));
        if (liveMode.active) {
          updateLiveSong(data.id!);
        }
      });
  }, [songId]);

  return <>{song.data ? <SongComponent loadedSong={song.data} /> : <LoadingHolderComponent fetchData={song} />}</>;
}

export default SongLoaderComponent;
