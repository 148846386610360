import { useEffect, useState } from 'react';
import { SongInterface } from 'zero-chords-shared';
import { fetchApproveSong, fetchDeleteSong, fetchSong } from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchDataState, getAxiosErrorMsg, isAdminUser } from '../../../utils';
import EditSongStep3Component from '../edit/EditSongStep3';
import LoadingHolderComponent from '../../loading/LoadingHolder';
import { Button, Stack } from 'react-bootstrap';
import HelperAlertComponent from '../../misc/HelperAlert';
import ConfirmationMenuComponent from '../../menu/ConfirmationMenu';

function ApproveSongComponent() {
  const { songId } = useParams();
  const [song, setSong] = useState(fetchDataState<SongInterface>({ data: null, loading: true }));
  const [helperText, setHelperText] = useState('');
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    songId &&
      fetchSong(songId)
        .then((data) => {
          // If it is already approved let's redirect
          if (data.approval) {
            navigate('/');
          }
          setSong(fetchDataState({ data }));
        })
        .catch((err) => setHelperText(getAxiosErrorMsg(err)));
  }, []);

  const handleDelete = () => {
    fetchDeleteSong(songId!)
      .then(() => navigate('/'))
      .catch((err) => setHelperText(getAxiosErrorMsg(err)));
  };

  const handleApprove = () => {
    fetchApproveSong(song.data!)
      .then(() => navigate('/'))
      .catch((err) => setHelperText(getAxiosErrorMsg(err)));
  };

  return (
    <>
      {song.data ? (
        <>
          <EditSongStep3Component newSong={song.data} isForApproval={true} />
          {isAdminUser() && (
            <Stack direction="horizontal" gap={3} className="justify-content-center mt-4">
              <Button variant="danger" onClick={() => handleApprove()}>
                Aprobar
              </Button>
              <Button variant="secondary" onClick={() => setShowConfirmation(true)}>
                Eliminar
              </Button>
            </Stack>
          )}
          <HelperAlertComponent helperText={helperText} />
        </>
      ) : (
        <LoadingHolderComponent fetchData={song} />
      )}
      {showConfirmation && <ConfirmationMenuComponent setShowConfirmation={setShowConfirmation} callBackFn={() => handleDelete()} message="eliminar el canto" />}
    </>
  );
}

export default ApproveSongComponent;
