import { HeaderEnum, SchemaInterface, SchemaStructureInterface } from 'zero-chords-shared';
import { axiosInstance } from './BaseService';

enum PATHS {
  SCHEMA_STRUCTURE_ID = 'schemas/structure/id',
  SCHEMA_STRUCTURE = 'schemas/structure',
  SCHEMAS_ID = 'schemas/id',
  SCHEMAS = 'schemas',
}

export const fetchSchemaStructure = async (): Promise<SchemaStructureInterface> => {
  const schemaStructure = await axiosInstance.get(PATHS.SCHEMA_STRUCTURE_ID);
  return schemaStructure.data;
};

export const fetchUpdateStructure = async (schemaStructure: SchemaStructureInterface): Promise<SchemaStructureInterface> => {
  const updatedStructure = await axiosInstance.put(PATHS.SCHEMA_STRUCTURE, schemaStructure);
  return updatedStructure.data;
};

export const fetchDeleteStructure = async (schemaStructureId: string): Promise<SchemaStructureInterface> => {
  const updatedStructure = await axiosInstance.delete(PATHS.SCHEMA_STRUCTURE, { headers: { [HeaderEnum.RESOURCE_ID]: schemaStructureId } });
  return updatedStructure.data;
};

export const fetchCreateStructure = async (schemaStructure: SchemaStructureInterface): Promise<SchemaStructureInterface> => {
  const createdStructure = await axiosInstance.post(PATHS.SCHEMA_STRUCTURE, schemaStructure);
  return createdStructure.data;
};

export const fetchSchema = async (schemaId: string): Promise<SchemaInterface> => {
  const schemaResponse = await axiosInstance.get(PATHS.SCHEMAS_ID, { headers: { [HeaderEnum.RESOURCE_ID]: schemaId } });
  return schemaResponse.data;
};

export const fetchCreateSchema = async (schema: SchemaInterface): Promise<SchemaInterface> => {
  const schemaResponse = await axiosInstance.post(PATHS.SCHEMAS, schema);
  return schemaResponse.data;
};

export const fetchUpdateSchema = async (schema: SchemaInterface): Promise<SchemaInterface> => {
  const schemaResponse = await axiosInstance.put(PATHS.SCHEMAS, schema);
  return schemaResponse.data;
};
