import { HeaderEnum, LiveInterface } from 'zero-chords-shared';
import { axiosInstance } from './BaseService';

enum PATHS {
  LIVE = 'live',
  LIVE_ID = 'live/id',
  LIVE_STATUS = 'live/status',
  LIVE_BY_USER = 'live/byUserId',
}

export const fetchLive = async ({ liveId }: { liveId?: string }): Promise<LiveInterface> => {
  let headers = {};
  if (liveId) headers = { [HeaderEnum.RESOURCE_ID]: liveId };

  const liveResponse = await axiosInstance.get(liveId ? PATHS.LIVE_ID : PATHS.LIVE_BY_USER, { headers });
  return liveResponse.data;
};

export const fetchLiveStatus = async (liveId: string): Promise<LiveInterface> => {
  const headers = { [HeaderEnum.RESOURCE_ID]: liveId };
  const liveResponse = await axiosInstance.get(PATHS.LIVE_STATUS, { headers });
  return liveResponse.data;
};

export const fetchUpdateLive = async (liveInfo: Partial<LiveInterface>): Promise<LiveInterface> => {
  const liveResponse = await axiosInstance.post(PATHS.LIVE, liveInfo);
  return liveResponse.data;
};

export const fetchStopLive = async (liveId: string): Promise<boolean> => {
  const liveResponse = await axiosInstance.delete(PATHS.LIVE, { headers: { [HeaderEnum.RESOURCE_ID]: liveId } });
  return liveResponse.data;
};
