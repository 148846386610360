import { HeaderEnum, UserAuthInterface, UserInterface } from 'zero-chords-shared';
import { axiosInstance } from './BaseService';

enum PATHS {
  USER_ID = 'user/id',
  USER_SEARCH = 'user/search',
  USER_AUTH = 'user/auth/login',
  USER_PASSWORD = 'user/password',
}

export const fetchGetUser = async (userId: string): Promise<UserInterface> => {
  const userResponse = await axiosInstance.get(PATHS.USER_ID, { headers: { [HeaderEnum.USER_ID]: userId } });
  return userResponse.data;
};

export const fetchSearchUser = async (username: string): Promise<UserInterface> => {
  const userResponse = await axiosInstance.get(PATHS.USER_SEARCH, { headers: { [HeaderEnum.RESOURCE_ID]: username } });
  return userResponse.data;
};

export const fetchUserLogin = async ({ username, password }: { username: string; password: string }): Promise<UserAuthInterface> => {
  const userResponse = await axiosInstance.get(PATHS.USER_AUTH, { headers: { username, password } });
  return userResponse.data;
};

export const fetchUpdateAuth = async (userId: string, password: string): Promise<UserAuthInterface> => {
  const userResponse = await axiosInstance.put(PATHS.USER_PASSWORD, { id: userId, auth: { password } });
  return userResponse.data;
};
