function LineBreakComponent({ count }: { count: number }) {
  return (
    <>
      {Array.from({ length: count }, (_v: number, k: number) => {
        return <br key={`newLine-${k}`} />;
      })}
    </>
  );
}

export default LineBreakComponent;
