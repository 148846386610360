import * as Icon from 'react-bootstrap-icons';
import { useState } from 'react';
import ShareMenuComponent from '../misc/ShareMenu';
import ModalMenuComponent from '../menu/ModalMenu';
import SongSearchListComponent from '../song/SongSearchList';

export function SchemaSongListMenuComponent({
  schemaId,
  onClick,
  deleteAction,
  moveAction,
}: {
  schemaId: string;
  onClick?: (songId: string) => void;
  deleteAction: {
    showDeleteAction: boolean;
    setShowDeleteAction: (value: boolean) => void;
  };
  moveAction: {
    showMoveAction: boolean;
    setShowMoveAction: (value: boolean) => void;
  };
}) {
  const [displaySongList, setDisplaySongList] = useState(false);

  const toggleShowMenu = () => setDisplaySongList(!displaySongList);

  const resetActions = () => {
    deleteAction.setShowDeleteAction(false);
    moveAction.setShowMoveAction(false);
  };

  return (
    <>
      <div>
        {displaySongList && (
          <ModalMenuComponent setDisplayMenu={setDisplaySongList} overrideClassNames={'overlay-song-list'}>
            <SongSearchListComponent title="Agregar a esquema" onClickCallback={onClick} showBackButton={false} showSongListMenu={false} />
          </ModalMenuComponent>
        )}
        <div className="bottomButtonHolder">
          <Icon.PlusCircleFill
            size={`1.8em`}
            onClick={() => {
              resetActions();
              toggleShowMenu();
            }}
            className="bottomButton"
          />
          <Icon.ArrowDownUp
            size={`1.8em`}
            onClick={() => {
              resetActions();
              moveAction.setShowMoveAction(!moveAction.showMoveAction);
            }}
            className="bottomButton"
          />
          <Icon.Trash3Fill
            size={`1.8em`}
            onClick={() => {
              resetActions();
              deleteAction.setShowDeleteAction(!deleteAction.showDeleteAction);
            }}
            className="bottomButton"
          />
          <ShareMenuComponent shareTitle="Compartir esquema" shareId={schemaId} shareType="schema" />
        </div>
      </div>
    </>
  );
}

export default SchemaSongListMenuComponent;
