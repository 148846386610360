import * as Icon from 'react-bootstrap-icons';
import { useState } from 'react';
import { useSongs } from '../../hooks/SongHook';
import TagButtonComponent from './TagButton';
import { Badge, Button } from 'react-bootstrap';
import styles from './../../styles/Tags.module.css';
import { TAG_LIST, TagInterface, TagTypeEnum } from 'zero-chords-shared';
import ModalMenuComponent from '../menu/ModalMenu';
import LineBreakComponent from '../misc/LineBreak';

export function TagsMenuComponent() {
  const [displayMenu, setDisplayMenu] = useState(false);
  const { songFilters, cleanTagFilters } = useSongs();
  const filterCount = songFilters.categories?.length ?? 0;

  const categoriesSet = Object.values(TagTypeEnum).map((currCategory) => {
    return {
      type: currCategory,
      tags: TAG_LIST.filter((category) => category.tagType === currCategory),
    };
  });

  const getTagsPerCategory = (category: { type: TagTypeEnum; tags: TagInterface[] }) => {
    return (
      <>
        <h5 className={styles['grayText']}>Categorías {category.type}</h5>
        <div className={styles['tagHolder']}>
          {category.tags.map((tag, i) => (
            <TagButtonComponent tag={tag} key={tag.value + i} />
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      {displayMenu && (
        <ModalMenuComponent setDisplayMenu={setDisplayMenu}>
          {getTagsPerCategory(categoriesSet.find((category) => category.type === TagTypeEnum.RELIGIOUS)!)}
          <hr />
          {getTagsPerCategory(categoriesSet.find((category) => category.type === TagTypeEnum.MASS)!)}
          <hr />
          {getTagsPerCategory(categoriesSet.find((category) => category.type === TagTypeEnum.MASS_TIME)!)}
          {filterCount > 0 && (
            <Button
              variant="danger"
              onClick={() => {
                cleanTagFilters();
                setDisplayMenu(false);
              }}
            >
              Limpiar filtros
            </Button>
          )}
          <LineBreakComponent count={3} />
        </ModalMenuComponent>
      )}
      <div className="iconHolder" onClick={() => setDisplayMenu(true)}>
        <Icon.Tags size={'1.4em'} />
        {filterCount > 0 && (
          <Badge bg="danger" pill style={{ marginLeft: 4 }}>
            {filterCount}
          </Badge>
        )}
      </div>
    </>
  );
}

export default TagsMenuComponent;
