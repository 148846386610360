import { SchemaInterface, SchemaStructureInterface } from 'zero-chords-shared';

export const getSchemas = (baseStructure: SchemaStructureInterface): SchemaInterface[] => {
  const innerSchemas = baseStructure.directories!.map((directory) => getSchemas(directory));
  return [...baseStructure.schemas, ...innerSchemas.flat()];
};

export const findSchemaPath = (path: string, currSchema: SchemaStructureInterface): SchemaStructureInterface | undefined => {
  const pathElements = path.split('/');
  const pathWay = pathElements.slice(1, pathElements.length - 1);

  let result: SchemaStructureInterface | undefined = currSchema;
  for (let p of pathWay) {
    result = result!.directories!.find((directory) => directory.path === p);

    if (!result) return;
  }

  return result;
};

export const getSchemaById = (schemaStructure: SchemaStructureInterface, schemaId: string) => getSchemas(schemaStructure).find((schema) => schema.id === schemaId);
