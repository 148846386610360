import { Badge } from 'react-bootstrap';
import { useLive } from '../../hooks/LiveHook';

function LiveBadgeComponent() {
  const { liveMode, toggleMode } = useLive();

  return (
    <Badge className="mx-4" onClick={toggleMode} bg={liveMode.active ? 'danger' : 'secondary'}>
      Live {liveMode.active ? 'On' : 'Off'}
    </Badge>
  );
}

export default LiveBadgeComponent;
