import { QRCode } from 'react-qrcode-logo';

export function QRComponent({ link }: { link: string }) {
  const getQR = () => {
    return (
      <a href={`whatsapp://send?text=${encodeURIComponent(link)}`} data-action="share/whatsapp/share">
        <QRCode value={link} />
      </a>
    );
  };

  return getQR();
}

export default QRComponent;
