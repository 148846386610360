import { useReducer } from 'react';
import { UserAuthInterface, UserInterface } from '../../../../shared/src/interfaces';
import { USER_ACTIONS_ENUM } from '../../enums/Reducer.enums';
import { UserReducer } from '../../reducers/UserReducer';
import { getUserInfoFromLocalStorage } from '../../utils';

export function useUserReducer() {
  const [state, dispatch] = useReducer(UserReducer, getUserInfoFromLocalStorage());

  const setUser = (user?: UserInterface) =>
    dispatch({
      type: USER_ACTIONS_ENUM.SET_USER,
      payload: { user },
    });

  const setUserAuth = (userAuth?: UserAuthInterface) =>
    dispatch({
      type: USER_ACTIONS_ENUM.SET_USER_AUTH,
      payload: { userAuth },
    });

  return { state, setUser, setUserAuth };
}
