import { Button, Stack } from 'react-bootstrap';
import ConfirmationMenuComponent from './../../menu/ConfirmationMenu';
import { useState } from 'react';
import LineBreakComponent from '../../misc/LineBreak';

function SongFlowButtonsComponent({
  currStep,
  setCurrStep,
  stepValidator,
  confirmAction,
}: {
  currStep: number;
  setCurrStep: (step: number) => void;
  stepValidator: () => boolean;
  confirmAction: () => void;
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  return (
    <div className="mt-3">
      <Stack direction="horizontal" gap={3} className="justify-content-center">
        {currStep > 1 && (
          <Button
            variant="secondary"
            onClick={() => {
              setCurrStep(currStep - 1);
            }}
          >
            Anterior
          </Button>
        )}
        {currStep < 3 && (
          <Button
            variant="danger"
            onClick={() => {
              stepValidator() && setCurrStep(currStep + 1);
            }}
          >
            Siguiente
          </Button>
        )}
        {currStep === 3 && (
          <Button variant="danger" onClick={() => setShowConfirmation(true)}>
            Confirmar
          </Button>
        )}
      </Stack>
      {showConfirmation && <ConfirmationMenuComponent setShowConfirmation={setShowConfirmation} callBackFn={confirmAction} message={'enviar el canto para validación'} />}
      <LineBreakComponent count={3} />
    </div>
  );
}

export default SongFlowButtonsComponent;
