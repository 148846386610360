import { useParams } from 'react-router-dom';
import SongListComponent from '../song/list/SongList';
import SchemaSongListMenuComponent from './SchemaSongListMenu';
import { Toast } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { SchemaInterface, SchemaSongInterface } from 'zero-chords-shared';
import { fetchSchema, fetchUpdateSchema } from '../../services';
import { fetchDataState, isUserOwnResource } from '../../utils';
import LoadingHolderComponent from '../loading/LoadingHolder';

function SchemaSongListComponent() {
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const { schemaId } = useParams();
  const [showDeleteAction, setShowDeleteAction] = useState(false);
  const [showMoveAction, setShowMoveAction] = useState(false);
  const [schema, setSchema] = useState(fetchDataState<SchemaInterface>({ data: null, loading: true }));

  useEffect(() => {
    if (schemaId) {
      getSchemas(schemaId);
    }
  }, []);

  const getSchemas = (schemaId: string) =>
    fetchSchema(schemaId)
      .then((data) => {
        setSchema(fetchDataState({ data }));
      })
      .catch(() => setSchema(fetchDataState<SchemaInterface>({ data: null, error: true })));

  const updateSchema = (newSchema: SchemaInterface, toastTxt: string) => {
    fetchUpdateSchema(newSchema).then((data) => {
      setSchema(fetchDataState({ data }));
      setToastText(toastTxt);
      setShowToast(true);
    });
  };

  const addToSchema = (songId: string) => {
    const newSchema = structuredClone(schema.data)!;
    if (!newSchema.songs.some((schemaSong: SchemaSongInterface) => schemaSong.song.id === songId)) {
      newSchema.songs.push({ song: { id: songId }, order: schema.data!.songs.length });
      updateSchema(newSchema, 'Agregada');
    }
  };

  const removeFromSchema = (songId: string) => {
    const newSchema = structuredClone(schema.data)!;
    newSchema.songs = newSchema.songs.filter((schemaSong: SchemaSongInterface) => schemaSong.song.id !== songId);
    updateSchema(newSchema, 'Eliminada');
  };

  const moveSongOrder = (orgIdx: number, movement: number) => {
    const newSchema = structuredClone(schema.data)!;
    const songItem = newSchema.songs.splice(orgIdx, 1)[0];
    newSchema.songs.splice(orgIdx! + movement, 0, songItem);
    newSchema.songs = newSchema.songs.map((schemaSong: SchemaSongInterface, idx: number) => {
      return { order: idx, song: schemaSong.song };
    });
    updateSchema(newSchema, 'Movida');
  };

  const handleSongClick = (songId: string) => {
    // TODO: Navigate not working great
    window.location.href = `/schema/${schemaId}/song/${songId}`;
  };

  return (
    <>
      {schema.data ? (
        <>
          <div className="toastHolder">
            <Toast show={showToast} onClose={() => setShowToast(false)} className="mb-2 toastMsg" delay={800} autohide>
              <Toast.Body>{toastText}</Toast.Body>
            </Toast>
          </div>
          <div>
            <SongListComponent
              songList={schema.data.songs}
              title={schema.data.title.toUpperCase()}
              deleteAction={{ showDeleteAction, onClickCallback: removeFromSchema }}
              moveAction={{ showMoveAction, onClickCallback: moveSongOrder }}
              showIdx={true}
              onClickCallback={handleSongClick}
            />
          </div>
          {isUserOwnResource(schema.data.owner?.id) && (
            <SchemaSongListMenuComponent
              schemaId={schema.data?.id!}
              onClick={addToSchema}
              deleteAction={{ showDeleteAction, setShowDeleteAction }}
              moveAction={{ showMoveAction, setShowMoveAction }}
            />
          )}
        </>
      ) : (
        <LoadingHolderComponent fetchData={schema} />
      )}
    </>
  );
}

export default SchemaSongListComponent;
