import * as Icon from 'react-bootstrap-icons';
import { useState } from 'react';
import NewSchemaComponent from './NewSchemaItem';
import { SchemaInterface, SchemaStructureInterface } from 'zero-chords-shared';
import ModalMenuComponent from '../menu/ModalMenu';

export function SchemaListMenuComponent({
  showLeftAction,
  setShowLeftAction,
  addFolder,
  addSchema,
}: {
  onClick?: (songId: string) => void;
  showLeftAction: boolean;
  setShowLeftAction: (value: boolean) => void;
  addFolder: (value: SchemaStructureInterface) => void;
  addSchema: (value: SchemaInterface) => void;
}) {
  const [displayMenu, setDisplayMenu] = useState(false);

  const resetActions = () => {
    setShowLeftAction(false);
    setDisplayMenu(true);
  };

  return (
    <>
      {displayMenu && (
        <ModalMenuComponent setDisplayMenu={setDisplayMenu}>
          <NewSchemaComponent setShowMenu={setDisplayMenu} addFolder={addFolder} addSchema={addSchema} />
        </ModalMenuComponent>
      )}
      <div className="bottomButtonHolder">
        <Icon.PlusCircleFill size={`1.8em`} onClick={() => resetActions()} className="bottomButton" />
        <div>&nbsp;&nbsp;&nbsp;</div>
        <Icon.Trash3Fill size={`1.8em`} onClick={() => setShowLeftAction(!showLeftAction)} className="bottomButton" />
      </div>
    </>
  );
}

export default SchemaListMenuComponent;
