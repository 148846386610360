import { SongBodyInterface, KEYS, KeysEnum, SongInterface } from 'zero-chords-shared';

const MAX_LINE_WIDTH = 40;

const transposeKey = (key: string, direction: number): string => {
  let tempKey = key;
  if (key.length > 2) tempKey = key.substring(0, 2);
  else if (key.length === 2 && key[1] !== '#') tempKey = key.substring(0, 1);

  const isMajor = tempKey[0] === tempKey[0].toUpperCase();
  if (!isMajor) tempKey = tempKey.slice(0, 1).toUpperCase() + tempKey.slice(1) ?? '';

  let newIndex = KEYS.indexOf(tempKey) + direction;
  if (newIndex < 0) newIndex = KEYS.length - 1;
  else if (newIndex > KEYS.length - 1) newIndex = 0;
  let newKey = KEYS[newIndex];

  if (!isMajor) {
    newKey = newKey.slice(0, 1).toLowerCase() + newKey.slice(1) ?? '';
    tempKey = tempKey.slice(0, 1).toLowerCase() + tempKey.slice(1) ?? '';
  }

  return key.replace(tempKey, newKey);
};

const transposeWithCache = (cache: Map<string, string>, origKey: string, direction: number): string => {
  let newKey = cache.get(origKey);
  if (!newKey) {
    newKey = transposeKey(origKey, direction);
    cache.set(origKey, newKey);
  }
  return newKey;
};

export const transposeChords = (lines: SongBodyInterface[], direction: number): SongBodyInterface[] => {
  const cache = new Map<string, string>();
  return lines.map((line) => {
    if (!line.chords) return line;
    let newLine = '';
    let temp = '';
    // Doing a char scan on the line
    for (let i = 0; i < line.value.length; i++) {
      const currChar = line.value.charAt(i);
      if (currChar === ' ') newLine += currChar;
      else {
        const nextChar = line.value.charAt(i + 1);
        temp += currChar;
        if (nextChar === ' ' || !nextChar) {
          newLine += transposeWithCache(cache, temp, direction);
          temp = '';
        }
      }
    }
    if (temp.length) {
      newLine += transposeWithCache(cache, temp, direction);
    }
    return { value: newLine, chords: true };
  });
};

export const getMainKey = (firstLine: string): KeysEnum | null => {
  const firstKey = firstLine
    .split(' ')
    .map((words) => words.trim())
    .filter(Boolean)[0]
    ?.substring(0, 2);

  if (Object.values(KeysEnum).includes(firstKey as KeysEnum)) return firstKey as KeysEnum;

  return null;
};

export const getSongBodyFromText = (bodyText: string): SongBodyInterface[] => {
  const result: SongBodyInterface[] = [];
  for (const line of bodyText.split('\n')) {
    if (line.length > MAX_LINE_WIDTH) {
      const firstLine = line.substring(0, 36);
      result.push({ value: firstLine, chords: isChordLine(firstLine) });
      const secondLine = line.substring(36);
      result.push({ value: secondLine, chords: isChordLine(secondLine) });
    } else if (line.length == 0) {
      result.push({ value: ' ', chords: false });
    } else {
      result.push({ value: line, chords: isChordLine(line) });
    }
  }
  return result;
};

export const getSongTextFromBody = (song: SongInterface): string =>
  song.body
    .map((line) => {
      if (line.value === ' ') return '';
      else return line.value;
    })
    .join('\n');

const CHORD_REGEX = /^[A-Ga-g](b|#)?(maj|dim|aug)?[2-9]*((sus|b|#)[2-9])?(\((11|13)\))?(\/[A-Ga-g](b|#)?)?$/;

export const isChordLine = (line: string): boolean => {
  const elems: string[] = line.split(' ').filter((word) => word.trim().length > 0);
  return elems.every((elem) => {
    const validator = new RegExp(CHORD_REGEX, 'g');
    return validator.test(elem);
  });
};
