import { useEffect, useState } from 'react';
import { SchemaSongInterface } from 'zero-chords-shared';
import SongListComponent from '../list/SongList';
import { fetchSongsForApproval } from '../../../services';
import { useNavigate } from 'react-router-dom';

function ApprovalListComponent() {
  const [songList, setSongList] = useState<SchemaSongInterface[] | null>(null);
  const [songId, setSongId] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSongsForApproval().then((data) => {
      const songHolder: SchemaSongInterface[] = [];
      data.forEach((song, order) => {
        songHolder.push({ song, order });
      });
      setSongList(songHolder);
    });
  }, []);

  useEffect(() => {
    if (songId) {
      navigate(`/song-approve/${songId}`);
    }
  }, [songId]);

  const clickHandler = (clickedSongId: string) => {
    setSongId(clickedSongId);
  };

  return (
    <>
      {(songList?.length ?? 0) > 0 && (
        <div className="m-4">
          <h4>Cantos pendientes de aprobar</h4>
          {<SongListComponent songList={songList!} onClickCallback={clickHandler} />}
        </div>
      )}
    </>
  );
}

export default ApprovalListComponent;
