import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { SchemaInterface, SchemaStructureInterface } from 'zero-chords-shared';
import { fetchSchemaStructure, fetchUpdateStructure } from '../../services';
import { fetchDataState, findSchemaPath } from '../../utils';
import SchemaDirectoryComponent from './SchemaDirectories';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingHolderComponent from '../loading/LoadingHolder';
import HelperAlertComponent from '../misc/HelperAlert';

export function SchemaImportComponent() {
  const [selectedPath, setSelectedPath] = useState('/');
  const [schemaStructure, setSchemaStructure] = useState(fetchDataState<SchemaStructureInterface>({ data: null, loading: true }));
  const { schemaId } = useParams();
  const [helperText, setHelperText] = useState('');
  const navigate = useNavigate();

  const getUserSchemas = () =>
    fetchSchemaStructure()
      .then((data) => {
        setSchemaStructure(fetchDataState({ data }));
      })
      .catch(() => setSchemaStructure(fetchDataState<SchemaStructureInterface>({ data: null, error: true })));

  useEffect(() => {
    getUserSchemas();
  }, []);

  const importSchema = () => {
    setHelperText('');
    const newSchemaStructure = findSchemaPath(selectedPath, schemaStructure.data!);
    newSchemaStructure?.schemas.push({ id: schemaId } as SchemaInterface);
    fetchUpdateStructure(newSchemaStructure!)
      .then(() => {
        navigate('/schema-list');
      })
      .catch(() => setHelperText('Ocurrió un error :('));
  };

  return (
    <>
      {schemaStructure.data ? (
        <>
          <div className="d-flex justify-content-center align-items-center ">
            <h1 className="songListTitle flex-grow-1">Importar esquema en:</h1>
          </div>
          <SchemaDirectoryComponent showLeftAction={false} schemaStructure={schemaStructure.data!} selectedPath={selectedPath} setSelectedPath={setSelectedPath} />
          <Button className="mt-5" variant="secondary" onClick={importSchema}>
            Importar
          </Button>
          <HelperAlertComponent helperText={helperText} />
        </>
      ) : (
        <LoadingHolderComponent fetchData={schemaStructure} />
      )}
    </>
  );
}

export default SchemaImportComponent;
