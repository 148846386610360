import { ListGroup } from 'react-bootstrap';
import { FetchDataInterface } from '../../interfaces/FetchDataInterface';
import SpinnerComponent from '../misc/Spinner';
import ModalMenuComponent from '../menu/ModalMenu';

function LoadingHolderComponent({ fetchData }: { fetchData: FetchDataInterface<unknown> }) {
  if (fetchData.loading)
    return (
      <>
        <ModalMenuComponent>
          <SpinnerComponent />
          <span className={`text-white fs-3`}>Cargando...</span>
        </ModalMenuComponent>
      </>
    );

  if (fetchData.error)
    return (
      <div className="d-flex flex-column m-4">
        <div>Ocurrió un problema...&#128517;</div>
      </div>
    );

  return (
    <ListGroup>
      <ListGroup.Item className="d-flex align-items-start" key={'no-list-items'}>
        No hay resultados...
      </ListGroup.Item>
    </ListGroup>
  );
}

export default LoadingHolderComponent;
