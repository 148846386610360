import { createContext } from 'react';
import { SongContextInterface } from '../interfaces/ContextInterface';
import { useFilterReducer } from './hooks/FilterReducerHook';
import { useUserReducer } from './hooks/UserReducerHook';
import { useLiveReducer } from './hooks/LiveReducerHook';

export const AppContext = createContext({} as SongContextInterface);

export function AppContextProvider({ children }: any) {
  const { state: songFilters, setFilters, cleanTagFilters, cleanAllFilters } = useFilterReducer();
  const { state: userInfo, setUser, setUserAuth } = useUserReducer();
  const { state: liveMode, startLive, stopLive } = useLiveReducer();

  return (
    <AppContext.Provider
      value={{
        songFilters,
        setFilters,
        cleanTagFilters,
        cleanAllFilters,
        userInfo,
        setUser,
        setUserAuth,
        liveMode,
        startLive,
        stopLive,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
