import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { TagInterface } from 'zero-chords-shared';

function SongCategoryButton({ tag, handleClick, defaultStatus = false }: { tag: TagInterface; handleClick?: (tag: TagInterface, status: boolean) => void; defaultStatus: boolean }) {
  const [status, setStatus] = useState(defaultStatus);
  return (
    <Button
      variant="secondary"
      className={`tagButton m-1 ${!handleClick || status ? 'buttonSelectedOn' : 'buttonSelectedOff'}`}
      onClick={() => {
        if (handleClick) {
          const newStatus = !status;
          handleClick(tag, newStatus);
          setStatus(newStatus);
        }
      }}
    >
      {tag.view}
    </Button>
  );
}

export default SongCategoryButton;
