import ApprovalListComponent from './song/approval/ApprovalList';

function HomeComponent() {
  return (
    <>
      <div className="homeHolder d-flex flex-column">
        <blockquote className="blockquote flex-grow-1">
          <p className="mb-0">Canten, canten a Dios; entonen salmos a nuestro rey; Dios que es el rey de toda la tierra, cántenle un himno de alabanza.</p>
          <br />
          <footer className="blockquote-footer">Salmo 47,7-8</footer>
        </blockquote>
      </div>
      <ApprovalListComponent />
    </>
  );
}

export default HomeComponent;
