import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchLiveStatus } from '../../services/LiveService';
import { LiveInterface } from 'zero-chords-shared';
import { fetchDataState } from '../../utils';
import LoadingHolderComponent from '../loading/LoadingHolder';
import SpinnerComponent from '../misc/Spinner';
import SongLoaderComponent from '../song/SongLoader';
import { Badge } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

function LiveViewComponent() {
  const { liveId } = useParams();
  const [liveInfo, setLifeInfo] = useState(fetchDataState<LiveInterface>({ data: null, loading: true }));
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const intervalId = setInterval(() => {
      liveId &&
        fetchLiveStatus(liveId).then((data: LiveInterface) => {
          if (!isMounted) return;
          setLifeInfo(fetchDataState({ data }));
        });
    }, 3000);

    return () => {
      clearInterval(intervalId);
      isMounted = false;
    };
  }, []);

  return (
    <>
      {liveInfo.data ? (
        liveInfo.data.song ? (
          <>
            <Badge bg="danger">Live</Badge>
            <SongLoaderComponent fixedSongId={liveInfo.data.song.id!} />
          </>
        ) : (
          <>
            <SpinnerComponent />
            <br />
            <Icon.ArrowReturnLeft onClick={() => navigate(-1)} />
          </>
        )
      ) : (
        <LoadingHolderComponent fetchData={liveInfo} />
      )}
    </>
  );
}

export default LiveViewComponent;
