import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { fetchGetUser, fetchUpdateAuth, fetchUserLogin } from '../services/UserService';
import { setUserInfoToLocalStorage } from '../utils';

export function useUser() {
  const { userInfo, setUser, setUserAuth } = useContext(AppContext);

  const authenticate = async (authInfo: { username: string; password: string }): Promise<void> => {
    const userAuthInfo = await fetchUserLogin(authInfo);
    setUserAuth(userAuthInfo);

    // Workaround to save Token before next getUser call
    setUserInfoToLocalStorage({ ...userInfo, userAuth: userAuthInfo });

    const userApiInfo = await fetchGetUser(userAuthInfo.id!);
    setUser(userApiInfo);
  };

  const updatePassword = async (newPassword: string): Promise<void> => {
    fetchUpdateAuth(userInfo.user?.id!, newPassword);
  };

  const logOff = async (): Promise<void> => {
    setUserAuth();
    setUser();
  };

  return {
    userInfo,
    setUser,
    setUserAuth,
    authenticate,
    logOff,
    updatePassword,
  };
}
