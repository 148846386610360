import { AdminRoles } from 'zero-chords-shared';
import { UserInfoInterface } from '../interfaces/ReducerInterface';

export const getUserInfoFromLocalStorage = (): UserInfoInterface | {} => {
  const storageVal = localStorage.getItem('userInfo');
  if (!storageVal?.length) return {};
  try {
    return JSON.parse(storageVal);
  } catch {
    return {};
  }
};

export const setUserInfoToLocalStorage = (userInfo: UserInfoInterface) => {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

export const isUserOwnResource = (resourceOwnerId: string | undefined): boolean => {
  if (!resourceOwnerId) return false;
  const user = getUserInfoFromLocalStorage();
  return (user as UserInfoInterface).userAuth?.id === resourceOwnerId;
};

export const isAdminUser = (): boolean => {
  const user = getUserInfoFromLocalStorage() as UserInfoInterface;
  return user.user?.roles.some((role) => AdminRoles.includes(role)) ?? false;
};
