import { Spinner } from 'react-bootstrap';

function SpinnerComponent() {
  return (
    <div className="d-flex justify-content-center m-4">
      <Spinner animation="border" variant="danger" />
    </div>
  );
}

export default SpinnerComponent;
