import { useReducer } from 'react';
import { LIVE_ACTIONS_ENUM } from '../../enums/Reducer.enums';
import { getLiveInfoFromLocalStorage } from '../../utils';
import { LiveReducer } from '../../reducers/LiveReducer';

export function useLiveReducer() {
  const [state, dispatch] = useReducer(LiveReducer, getLiveInfoFromLocalStorage());

  const startLive = (liveId: string) =>
    dispatch({
      type: LIVE_ACTIONS_ENUM.START_LIVE,
      payload: { active: true, liveId },
    });

  const stopLive = () =>
    dispatch({
      type: LIVE_ACTIONS_ENUM.STOP_LIVE,
      payload: { active: false },
    });

  return { state, startLive, stopLive };
}
