import { Badge, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SchemaListMenuComponent from './SchemaListMenu';
import { useEffect, useState } from 'react';
import { SchemaInterface, SchemaStructureInterface } from 'zero-chords-shared';
import animationStyle from './../../styles/Animation.module.css';
import { useSchemas } from '../../hooks/SchemaHook';
import { fetchCreateSchema, fetchCreateStructure, fetchDeleteStructure, fetchSchemaStructure, fetchUpdateStructure } from '../../services';
import { fetchDataState } from '../../utils';
import LoadingHolderComponent from '../loading/LoadingHolder';
import SchemaDirectoryComponent from './SchemaDirectories';
import DeleteItemFromListComponent from '../misc/DeleteItemFromList';
import LineBreakComponent from '../misc/LineBreak';

export function SchemaListComponent() {
  const [showLeftAction, setShowLeftAction] = useState(false);
  const { findSchemaPath } = useSchemas();
  const [selectedPath, setSelectedPath] = useState('/');
  const [schemaStructure, setSchemaStructure] = useState(fetchDataState<SchemaStructureInterface>({ data: null, loading: true }));

  const getUserSchemas = () =>
    fetchSchemaStructure()
      .then((data) => {
        setSchemaStructure(fetchDataState({ data }));
      })
      .catch(() => setSchemaStructure(fetchDataState<SchemaStructureInterface>({ data: null, error: true })));

  useEffect(() => {
    getUserSchemas();
  }, []);

  const removeSchema = (schemaFolder: SchemaStructureInterface, schemaId: string) => {
    const newSchemaFolder = structuredClone(schemaFolder);
    newSchemaFolder.schemas = newSchemaFolder.schemas.filter((schema: SchemaInterface) => schema.id !== schemaId);
    fetchUpdateStructure(newSchemaFolder).then(() => getUserSchemas());
  };

  const addSchema = (schema: SchemaInterface) => {
    const newSchemaStructure = findSchemaPath(selectedPath, schemaStructure.data!);
    fetchCreateSchema(schema).then((createdSchema: SchemaInterface) => {
      newSchemaStructure?.schemas.push(createdSchema);
      fetchUpdateStructure(newSchemaStructure!).then(() => getUserSchemas());
    });
  };

  const removeDirectory = (directoryId: string) => {
    fetchDeleteStructure(directoryId).then(() => {
      getUserSchemas();
    });
  };

  const addDirectory = (folder: SchemaStructureInterface) => {
    const newSchemaStructure = findSchemaPath(selectedPath, schemaStructure.data!);
    folder.parentDirectory = { id: newSchemaStructure?.id };
    fetchCreateStructure(folder).then(() => {
      getUserSchemas();
    });
  };

  const getSchemaItem = (schemaFolder: SchemaStructureInterface, schema: SchemaInterface) => (
    <div className="d-flex" key={schema.id + '-song-list-item'}>
      {showLeftAction && <DeleteItemFromListComponent callBackFn={() => removeSchema(schemaFolder, schema.id!)} message={'eliminar el esquema'} />}
      <Link style={{ textDecoration: 'none' }} to={`/schema/${schema.id}`} key={schema.id + '-link'} className="flex-grow-1">
        <ListGroup.Item className="d-flex justify-content-between align-items-start">
          <div className="ms-2 me-auto">
            <div className="fw-bold text-uppercase text-start">{schema.title}</div>
            <div className="text-start">{schema.description}</div>
          </div>
          <Badge bg="danger" pill>
            {schema.songs?.length ?? 0}
          </Badge>
        </ListGroup.Item>
      </Link>
    </div>
  );

  const getSchemas = (schemasInPath: SchemaStructureInterface | undefined) => {
    return !schemasInPath || !schemasInPath.schemas?.length ? (
      <ListGroup className={animationStyle['fadeAnimation']} key={`${schemasInPath?.path}-no-elems`}>
        <ListGroup.Item className="d-flex align-items-start" key={'no-list-items'}>
          No se han agregado esquemas
        </ListGroup.Item>
      </ListGroup>
    ) : (
      <ListGroup className={animationStyle['fadeAnimation']} key={`${schemasInPath?.path}-items`}>
        {schemasInPath?.schemas.map((schema) => getSchemaItem(schemasInPath, schema))}
      </ListGroup>
    );
  };

  const getSelectedPathStructure = () => {
    const selectedPathStructure = findSchemaPath(selectedPath, schemaStructure.data!);
    return <>{getSchemas(selectedPathStructure)}</>;
  };

  return (
    <>
      {schemaStructure.data ? (
        <>
          <SchemaDirectoryComponent
            showLeftAction={showLeftAction}
            schemaStructure={schemaStructure.data}
            selectedPath={selectedPath}
            setSelectedPath={setSelectedPath}
            removeDirectory={removeDirectory}
          />
          <div>{getSelectedPathStructure()}</div>
          <SchemaListMenuComponent showLeftAction={showLeftAction} setShowLeftAction={setShowLeftAction} addFolder={addDirectory} addSchema={addSchema} />
          <LineBreakComponent count={3} />
        </>
      ) : (
        <LoadingHolderComponent fetchData={schemaStructure} />
      )}
    </>
  );
}

export default SchemaListComponent;
