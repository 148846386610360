import { Button } from 'react-bootstrap';
import { TagInterface } from 'zero-chords-shared';
import { useSongs } from '../../hooks/SongHook';
import styles from './../../styles/Tags.module.css';

export function TagButtonComponent({ tag }: { tag: TagInterface }) {
  const { setFilters, songFilters } = useSongs();
  const isSelected = () => songFilters.categories?.some((category) => category === tag.value);

  return (
    <Button
      variant="secondary"
      className={`${styles['tagButton']} ${isSelected() ? 'buttonSelectedOn' : 'buttonSelectedOff'}`}
      onClick={() => setFilters({ category: { value: tag.value, flag: !isSelected() } })}
    >
      {tag.view}
    </Button>
  );
}

export default TagButtonComponent;
