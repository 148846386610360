import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { SongInterface } from 'zero-chords-shared';
import { transposeChords } from '../utils/Song.utils';

export function useSongs() {
  const { songFilters, setFilters, cleanTagFilters, cleanAllFilters } = useContext(AppContext);

  const transposeBody = (song: SongInterface, direction: number): SongInterface => {
    let newSong = structuredClone(song);
    newSong.body = transposeChords(newSong.body, direction);
    return newSong;
  };

  return {
    setFilters,
    songFilters,
    cleanTagFilters,
    cleanAllFilters,
    transposeBody,
  };
}
