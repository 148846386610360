import SongCardComponent from '../SongCard';
import { SchemaSongInterface, SongInterface } from 'zero-chords-shared';
import { ListGroup } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import animationStyle from './../../../styles/Animation.module.css';
import { useNavigate } from 'react-router-dom';
import SongListMenuComponent from './SongListMenu';
import DeleteItemFromListComponent from '../../misc/DeleteItemFromList';
import LineBreakComponent from '../../misc/LineBreak';

function SongListComponent({
  showBackButton = true,
  songList,
  title,
  onClickCallback,
  deleteAction,
  moveAction,
  showSongListMenu = false,
  showIdx = false,
}: {
  showBackButton?: boolean;
  songList: SchemaSongInterface[];
  title?: string;
  onClickCallback?: (songId: string) => void;
  deleteAction?: {
    showDeleteAction: boolean;
    onClickCallback: (songId: string) => void;
  };
  moveAction?: {
    showMoveAction: boolean;
    onClickCallback: (orgIdx: number, movement: number) => void;
  };
  showSongListMenu?: boolean;
  showIdx?: boolean;
}) {
  const displayedSongs = songList.map((data) => data.song as SongInterface);

  const navigate = useNavigate();

  const getArrowDown = (idx: number, songId: string, movement: number) =>
    idx < (displayedSongs.length ?? 0) - 1 && <Icon.ArrowDownCircle size={'1.5em'} key={songId + '-move-down'} onClick={() => moveAction!.onClickCallback(idx, movement)} />;

  const getArrowUp = (idx: number, songId: string, movement: number) =>
    idx > 0 && <Icon.ArrowUpCircle display={'false'} size={'1.5em'} key={songId + '-move-up'} onClick={() => moveAction!.onClickCallback(idx, movement)} />;

  return (
    <>
      <div className={`${animationStyle['slideDownAnimation']}`}>
        {title && (
          <div className="d-flex justify-content-center align-items-center ">
            {showBackButton && <Icon.ArrowReturnLeft className="m-3" onClick={() => navigate(-1)} size={25} />}
            <h1 className="songListTitle flex-grow-1">{title}</h1>
          </div>
        )}
        <div>
          <ListGroup as="ol" className="cardHolder">
            {displayedSongs.map((song: SongInterface, idx: number) => (
              <div className="d-flex" key={song.id + '-container'}>
                {deleteAction?.showDeleteAction && <DeleteItemFromListComponent callBackFn={() => deleteAction.onClickCallback(song.id!)} message={'eliminar el canto del esquema'} />}
                {moveAction?.showMoveAction && (
                  <div className={`iconHolder doubleIcon ${animationStyle['slideRightAnimation']}`} key={song.id + '-move'}>
                    {getArrowDown(idx, song.id!, 1)}
                    {getArrowUp(idx, song.id!, -1)}
                  </div>
                )}
                <SongCardComponent song={song} key={song.id} idx={idx + 1} onClickCallback={onClickCallback} showIdx={showIdx} />
              </div>
            ))}
          </ListGroup>
          <LineBreakComponent count={3} />
        </div>
      </div>
      {showSongListMenu && <SongListMenuComponent />}
    </>
  );
}

export default SongListComponent;
