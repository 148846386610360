import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { fetchUpdateLive, fetchStopLive } from '../services/LiveService';

export function useLive() {
  const { liveMode, startLive, stopLive } = useContext(AppContext);

  const toggleMode = () => {
    if (liveMode.active) {
      if (liveMode.liveId) {
        fetchStopLive(liveMode.liveId);
      }
      stopLive();
    } else {
      fetchUpdateLive({}).then((data) => startLive(data.id!));
    }
  };

  const updateLiveSong = (songId: string) => {
    fetchUpdateLive({ song: { id: songId } }).then((data) => startLive(data.id!));
  };

  return {
    liveMode,
    toggleMode,
    updateLiveSong,
  };
}
