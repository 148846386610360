import { ListGroup } from 'react-bootstrap';
import { SchemaStructureInterface } from 'zero-chords-shared';
import styles from './Schema.module.css';
import animationStyle from './../../styles/Animation.module.css';
import { useSchemas } from '../../hooks/SchemaHook';
import * as Icon from 'react-bootstrap-icons';
import { useState } from 'react';
import DeleteItemFromListComponent from '../misc/DeleteItemFromList';

export function SchemaDirectoryComponent({
  schemaStructure,
  selectedPath = '/',
  setSelectedPath,
  removeDirectory,
  showLeftAction = true,
}: {
  schemaStructure: SchemaStructureInterface;
  selectedPath: string;
  setSelectedPath: (value: string) => void;
  removeDirectory?: (directoryId: string) => void;
  showLeftAction: boolean;
}) {
  const { findSchemaPath } = useSchemas();
  const [showDirectories, setShowDirectories] = useState(false);

  const getFolderItem = (path: string, depth: number, absolutePath: string) => {
    const currentDirectory = findSchemaPath(absolutePath, schemaStructure)!;
    return (
      <div className={`${absolutePath === selectedPath ? styles['selectedFolder'] : ''} d-flex`}>
        {showLeftAction && removeDirectory && absolutePath !== '/' && (
          <DeleteItemFromListComponent
            callBackFn={() => {
              removeDirectory(currentDirectory.id!);
              setSelectedPath('/');
            }}
            message={'eliminar la carpeta'}
          />
        )}
        <div
          className={`w-100 ${styles['schemaFolder']}`}
          style={{ marginLeft: `${20 + depth * 10}px` }}
          onClick={() => {
            setSelectedPath(absolutePath);
          }}
        >
          <Icon.Folder size={30} />
          <div className={`${styles['schemaFolderText']}`}>{path === '' ? '/' : path}</div>
        </div>
      </div>
    );
  };

  const getSchemaPathItems = (schemaStructure: SchemaStructureInterface, path: string = '', absolutePath = '/', depth: number = 0) => {
    const absolutePathElems = absolutePath.split('/');
    const absoluteCurrentPath = absolutePathElems.slice(0, absolutePathElems.length - 1).join('/');
    const showItem = selectedPath.includes(absoluteCurrentPath);

    return (
      <div key={`${absolutePath}-folder-item`} className={animationStyle['slideDownAnimation']}>
        {getFolderItem(path, depth, absolutePath)}
        <ListGroup>
          {schemaStructure.directories!.map((currentDirectory: SchemaStructureInterface) => {
            return showItem ? getSchemaPathItems(currentDirectory, currentDirectory.path, absolutePath + currentDirectory.path + '/', depth + 1) : '';
          })}
        </ListGroup>
      </div>
    );
  };

  const getPathToggle = () => {
    return <div className="m-3">{showDirectories ? <Icon.ArrowUp size={20} onClick={() => setShowDirectories(false)} /> : <Icon.ArrowDown size={20} onClick={() => setShowDirectories(true)} />}</div>;
  };

  const getJustSelectedPath = () => {
    return <ListGroup className={animationStyle['slideDownAnimation']}>{getFolderItem(selectedPath, 0, selectedPath)}</ListGroup>;
  };

  return (
    <>
      <div>{showDirectories ? getSchemaPathItems(schemaStructure) : getJustSelectedPath()}</div>
      {getPathToggle()}
    </>
  );
}

export default SchemaDirectoryComponent;
