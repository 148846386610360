import { SchemaStructureInterface } from 'zero-chords-shared';
import { getSchemaById } from '../utils/Schema.utils';

export function useSchemas() {
  const findSchemaPath = (path: string, currSchema: SchemaStructureInterface): SchemaStructureInterface | undefined => {
    const pathElements = path.split('/');
    const pathWay = pathElements.slice(1, pathElements.length - 1);

    let result: SchemaStructureInterface | undefined = currSchema;
    for (let p of pathWay) {
      result = result!.directories!.find((directory) => directory.path === p);

      if (!result) return;
    }

    return result;
  };

  return {
    findSchemaPath,
    getSchemaById,
  };
}
