import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { useState } from 'react';
import ModalMenuComponent from './ModalMenu';

export function MainMenuComponent() {
  const [displayMenu, setDisplayMenu] = useState(false);
  const hideMenu = () => setDisplayMenu(false);

  return (
    <>
      {displayMenu && (
        <ModalMenuComponent setDisplayMenu={setDisplayMenu} extraClassNames="menu-big-font-size">
          <Link to="/live" onClick={hideMenu} className="mt-5">
            Modo Live
          </Link>
          <Link to="/song-list" onClick={hideMenu} className="mt-5">
            Todos los cantos
          </Link>
          <Link to="/schema-list" onClick={hideMenu} className="mt-5">
            Esquemas
          </Link>
          <Link to="/profile" onClick={hideMenu} className="mt-5">
            Perfil
          </Link>
        </ModalMenuComponent>
      )}
      <Icon.MusicNoteList size={'1em'} onClick={() => setDisplayMenu(true)} />
    </>
  );
}

export default MainMenuComponent;
