export enum USER_ACTIONS_ENUM {
  SET_USER = 'SET_USER',
  SET_USER_AUTH = 'SET_USER_AUTH',
}

export enum FILTER_ACTIONS_ENUM {
  SET_FILTERS = 'SET_FILTERS',
  CLEAN_TAG_FILTERS = 'CLEAN_TAG_FILTERS',
  CLEAN_ALL_FILTERS = 'CLEAN_ALL_FILTERS',
}

export enum SONG_ACTIONS_ENUM {
  SET_SONGS = 'SET_SONGS',
}

export enum LIVE_ACTIONS_ENUM {
  START_LIVE = 'START_LIVE',
  STOP_LIVE = 'STOP_LIVE',
}
