import { Alert, ProgressBar } from 'react-bootstrap';
import { SongInterface } from 'zero-chords-shared';
import SongBodyComponent from '../SongBody';
import SongInfoComponent from '../SongInfo';

function EditSongStep3Component({ newSong, isForApproval = false }: { newSong: SongInterface; isForApproval?: boolean }) {
  return (
    <>
      <div className="m-4">
        <ProgressBar now={90} variant="danger" className="mt-3" />
        <SongInfoComponent song={newSong} showUser={isForApproval} />
      </div>
      <h6>Letra y acordes:</h6>
      <SongBodyComponent song={newSong} />
      {!isForApproval && (
        <Alert className="mt-4 mb-4" variant="secondary">
          <b>Importante:</b> Una vez terminado, solo el administrador podrá modificarlo.
        </Alert>
      )}
    </>
  );
}

export default EditSongStep3Component;
