import { HeaderEnum, SongFiltersInterface, SongInterface } from 'zero-chords-shared';
import { axiosInstance } from './BaseService';

enum PATHS {
  SONGS = 'songs',
  SONGS_APPROVE = 'songs/approve',
  SONGS_FOR_APPROVAL = 'songs/for-approval',
  SONGS_ID = 'songs/id',
  SONGS_SEARCH = 'songs/search',
}

export const fetchSongs = async (filters: SongFiltersInterface): Promise<SongInterface[]> => {
  const songs = await axiosInstance.post(PATHS.SONGS_SEARCH, filters);
  return songs.data;
};

export const fetchSongsForApproval = async (): Promise<SongInterface[]> => {
  const songs = await axiosInstance.get(PATHS.SONGS_FOR_APPROVAL);
  return songs.data;
};

export const fetchApproveSong = async (song: SongInterface): Promise<SongInterface> => {
  const songs = await axiosInstance.put(PATHS.SONGS_APPROVE, song);
  return songs.data;
};

export const fetchSong = async (songId: string): Promise<SongInterface> => {
  const songs = await axiosInstance.get(PATHS.SONGS_ID, { headers: { [HeaderEnum.RESOURCE_ID]: songId } });
  return songs.data;
};

export const fetchCreateSong = async (song: SongInterface): Promise<SongInterface> => {
  const songs = await axiosInstance.post(PATHS.SONGS, song);
  return songs.data;
};

export const fetchUpdateSong = async (song: SongInterface): Promise<SongInterface> => {
  const songs = await axiosInstance.put(PATHS.SONGS, song);
  return songs.data;
};

export const fetchDeleteSong = async (songId: string): Promise<SongInterface> => {
  const songs = await axiosInstance.delete(PATHS.SONGS, { headers: { [HeaderEnum.RESOURCE_ID]: songId } });
  return songs.data;
};
