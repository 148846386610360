import { Form } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useState } from 'react';
import { fetchSearchUser } from '../../services/UserService';
import { useUser } from '../../hooks/UserHook';
import HelperAlertComponent from '../misc/HelperAlert';
import { LiveInterface, UserInterface, UserLiveInterface } from 'zero-chords-shared';
import LiveUserListComponent from './LiveUserList';

function LiveShareComponent({ liveInfo, updateLiveInfo }: { liveInfo: LiveInterface; updateLiveInfo: (newValue: LiveInterface) => void }) {
  const [usernameToSearch, setUsernameToSearch] = useState('');
  const [helperText, setHelperText] = useState('');
  const user = useUser();

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') userSearch();
  };

  const handleDeleteUser = (deletedUserId: string) => {
    const newLiveInfo = structuredClone(liveInfo);
    newLiveInfo.followers = ((newLiveInfo.followers ?? []) as UserInterface[]).filter((viewer: UserInterface) => viewer.id !== deletedUserId);
    updateLiveInfo(newLiveInfo);
  };

  const userSearch = () => {
    if (usernameToSearch.length > 0) {
      setHelperText('');
      if (user.userInfo.user?.username === usernameToSearch) {
        setHelperText('No puedes seguirte a ti mismo');
        return;
      }
      if (liveInfo.followers?.some((viewer) => viewer.username === usernameToSearch)) {
        setHelperText('Ya se está compartiendo con ese usuario');
        return;
      }
      fetchSearchUser(usernameToSearch)
        .then((userData) => {
          const newfollowers = [...(liveInfo.followers ?? []), { id: userData.id }];
          const newLiveInfo: LiveInterface = { ...liveInfo, followers: newfollowers };
          setUsernameToSearch('');
          updateLiveInfo(newLiveInfo);
        })
        .catch(() => {
          setHelperText('No se encontró al usuario');
        });
    }
  };

  const hasFollowers = (liveInfo?.followers?.length ?? 0) > 0;

  return (
    <div className="d-flex flex-column mt-3 mx-5">
      <h5>Compartiendo con {`(${liveInfo?.followers?.length})`}:</h5>
      {hasFollowers ? <LiveUserListComponent users={liveInfo.followers as UserLiveInterface[]} handleDeleteUser={handleDeleteUser} /> : 'No se ha compartido con nadie'}
      <Form.Group className="mt-3" controlId="formAddUsr">
        <div className="d-flex flex-row align-middle">
          <Form.Control
            className="p-2"
            type="text"
            placeholder="Introduce el usuario para compartir"
            onChange={(e) => setUsernameToSearch(e.target.value)}
            maxLength={32}
            value={usernameToSearch}
            onKeyDown={handleKeyDown}
            required
          />
          <Icon.Search className="m-2" size={'1.4em'} onClick={userSearch} />
        </div>
      </Form.Group>
      <HelperAlertComponent helperText={helperText} />
    </div>
  );
}

export default LiveShareComponent;
