import { Accordion, Button, Form } from 'react-bootstrap';
import { useUser } from '../../hooks/UserHook';
import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import HelperAlertComponent from '../misc/HelperAlert';
import ConfirmationMenuComponent from '../menu/ConfirmationMenu';

function ProfileComponent() {
  const user = useUser();
  const [newPassword, setNewPassword] = useState<{ password: string; confirmation: string }>({ password: '', confirmation: '' });
  const [helperText, setHelperText] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const validateAndUpdatePassword = () => {
    setHelperText('');
    if (newPassword.password.length < 8) {
      setHelperText('La contraseña debe ser al menos de 8 caracteres');
      return;
    }
    if (newPassword.password != newPassword.confirmation) {
      setHelperText('Las contraseñas deben coincidir');
      return;
    }

    user.updatePassword(newPassword.password).then(() => user.logOff());
  };

  const logOff = () => {
    user.logOff();
  };

  return (
    <div className="m-4">
      <div>Perfil de usuario</div>
      <h3>{user.userInfo.user?.username}</h3>
      <h4>{user.userInfo.user?.name}</h4>
      {(user.userInfo.user?.email?.length ?? 0) > 0 && <h4>{user.userInfo.user?.email}</h4>}
      <h6>Tipo: {user.userInfo.user?.roles.join(',')}</h6>
      <Accordion className="mt-5">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Cambiar contraseña</Accordion.Header>
          <Accordion.Body>
            <Form.Group className="mb-3" controlId="formBasicUser">
              <Form.Label>Contraseña nueva</Form.Label>
              <div className="d-flex mx-auto">
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Escribe la contraseña nueva"
                  onChange={(e) => {
                    setNewPassword({ ...newPassword, password: e.target.value });
                  }}
                  required
                />
                <div className="align-middle m-2">
                  <Icon.Eye onClick={() => setShowPassword(!showPassword)} />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicUser">
              <Form.Label>Confirma contraseña</Form.Label>
              <div className="d-flex mx-auto">
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Confirma la contraseña nueva"
                  onChange={(e) => {
                    setNewPassword({ ...newPassword, confirmation: e.target.value });
                  }}
                  required
                />
                <div className="align-middle m-2">
                  <Icon.Eye onClick={() => setShowPassword(!showPassword)} />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicUser">
              <Button variant="secondary" className="mt-3" onClick={() => validateAndUpdatePassword()}>
                Actualizar
              </Button>
            </Form.Group>
            <HelperAlertComponent helperText={helperText} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Button variant="danger" className="mt-5" onClick={() => setShowConfirmation(true)}>
        Cerrar sesión
      </Button>
      {showConfirmation && <ConfirmationMenuComponent setShowConfirmation={setShowConfirmation} callBackFn={() => logOff()} message="cerrar sesión" />}
    </div>
  );
}

export default ProfileComponent;
