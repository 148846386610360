import { Badge, ListGroup } from 'react-bootstrap';
import { SongInterface } from 'zero-chords-shared';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

function SongCardComponent({ song, idx, onClickCallback, showIdx = true }: { song: SongInterface; idx: number; onClickCallback?: (songId: string) => void; showIdx?: boolean }) {
  return (
    <Link style={{ textDecoration: 'none' }} to={onClickCallback ? '#' : `/song/${song.id}`} onClick={() => onClickCallback && onClickCallback(song.id!)} className="flexGrow">
      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto cardHolder">
          <div className="fw-bold">
            {showIdx ? `${idx}.` : ''} {song.name.toUpperCase()} <Badge bg="danger">{song.key}</Badge>
          </div>
          <div className="cardBody" style={{ whiteSpace: 'nowrap' }}>
            {song.body
              .filter((body) => !body.chords)
              .map((line) => line.value)
              .join('')
              .substring(0, 40)
              .trim()}
            ...
          </div>
        </div>
        <div className="songViewHolder">
          <Icon.MusicNoteBeamed />
        </div>
      </ListGroup.Item>
    </Link>
  );
}

export default SongCardComponent;
