import './App.css';
import './styles/menu.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useUser } from './hooks/UserHook';
import HeaderComponent from './components/Header';
import HomeComponent from './components/Home';
import SchemaListComponent from './components/schema/SchemaList';
import SchemaSongListComponent from './components/schema/SchemaSongList';
import LoginComponent from './components/profile/Login';
import LiveViewComponent from './components/live/LiveView';
import ProfileComponent from './components/profile/Profile';
import SchemaImportComponent from './components/schema/SchemaImport';
import EditSongComponent from './components/song/edit/EditSong';
import ApproveSongComponent from './components/song/approval/ApproveSong';
import LogOffComponent from './components/Logoff';
import SongSearchListComponent from './components/song/SongSearchList';
import SongLoaderComponent from './components/song/SongLoader';
import SchemaSongViewerComponent from './components/schema/SchemaSongViewer';
import LiveComponent from './components/live/Live';

function App() {
  const user = useUser();

  return (
    <div className="App">
      <HeaderComponent />
      {user.userInfo.userAuth ? (
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/song-list" element={<SongSearchListComponent />} />
          <Route path="/profile" element={<ProfileComponent />} />
          <Route path="/schema/:schemaId" element={<SchemaSongListComponent />} />
          <Route path="/share/schema/:schemaId" element={<SchemaImportComponent />} />
          <Route path="/song-new" element={<EditSongComponent />} />
          <Route path="/song-approve/:songId" element={<ApproveSongComponent />} />
          <Route path="/song/:songId" element={<SongLoaderComponent />} />
          <Route path="/schema/:schemaId/song/:songId" element={<SchemaSongViewerComponent />} />
          <Route path="/share/song/:songId" element={<SongLoaderComponent />} />
          <Route path="/schema-list" element={<SchemaListComponent />} />
          <Route path="/live" element={<LiveComponent />} />
          <Route path="/live-view/:liveId" element={<LiveViewComponent />} />
          <Route path="/logoff" element={<LogOffComponent />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      ) : (
        <LoginComponent />
      )}
    </div>
  );
}

export default App;
