export function ModalMenuComponent({
  children,
  overrideClassNames = null,
  extraClassNames = '',
  setDisplayMenu,
}: {
  children: any;
  overrideClassNames?: string | null;
  extraClassNames?: string;
  setDisplayMenu?: (value: boolean) => void;
}) {
  return (
    <div id="myNav" className="overlay" style={{ display: 'block' }}>
      {setDisplayMenu && (
        <a className="closeBtn" onClick={() => setDisplayMenu(false)}>
          &times;
        </a>
      )}
      <div className={`${overrideClassNames ?? 'overlay-content'} ${extraClassNames}`}>{children}</div>
    </div>
  );
}

export default ModalMenuComponent;
