import { useSongs } from '../../hooks/SongHook';
import Form from 'react-bootstrap/Form';
import TagsMenuComponent from './TagsMenu';
import * as Icon from 'react-bootstrap-icons';
import styles from './../../styles/Tags.module.css';

function FiltersComponent({ searchSongs }: { searchSongs: () => void }) {
  const { setFilters, songFilters } = useSongs();

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') searchSongs();
  };

  return (
    <nav className={styles['filterHolder']}>
      <TagsMenuComponent />
      <Form.Control
        size="sm"
        type="search"
        placeholder="Buscar"
        className="inputSearch"
        value={songFilters.text ?? ''}
        onChange={(e) => setFilters({ text: e.target.value })}
        onKeyDown={handleKeyDown}
      />
      <div className={`iconHolder ${styles['filterHolder']}`}>
        <Icon.Search onClick={searchSongs} size={'1.4em'} />
      </div>
    </nav>
  );
}

export default FiltersComponent;
