import { Form, ProgressBar } from 'react-bootstrap';
import { KeysEnum, SongInterface } from 'zero-chords-shared';
import { getMainKey, getSongBodyFromText, getSongTextFromBody } from './../../../utils';

function EditSongStep2Component({ newSong, setNewSong }: { newSong: SongInterface; setNewSong: (newSong: SongInterface) => void }) {
  const getKeyOptions = () => {
    return Object.values(KeysEnum).map((key) => (
      <option value={key} key={`key-id-${key}`}>
        {key}
      </option>
    ));
  };

  const handleBodyChange = (value: string) => {
    const body = getSongBodyFromText(value);
    const key = getMainKey(body[0].value) ?? KeysEnum.C;
    setNewSong({ ...newSong, body, key });
  };

  return (
    <>
      <div className="m-4">
        <ProgressBar now={66} variant="danger" className="mt-3" />
      </div>
      <Form.Group className="mt-4" controlId="formNewSong">
        <h5>Letra y acordes*</h5>
        <Form.Control
          className="textArea"
          as="textarea"
          rows={20}
          placeholder="Introduce la letra y los acordes"
          onChange={(e) => handleBodyChange(e.target.value)}
          value={getSongTextFromBody(newSong)}
          style={{ padding: 0 }}
          required
        />
      </Form.Group>
      <Form.Group className="mt-4" controlId="formNewSong">
        <h5>Tono base</h5>
        <Form.Select
          aria-label="Selecciona el tono base"
          value={newSong.key}
          onChange={(e) => {
            const key = e.target.value as KeysEnum;
            setNewSong({ ...newSong, key });
          }}
        >
          {getKeyOptions()}
        </Form.Select>
      </Form.Group>
    </>
  );
}

export default EditSongStep2Component;
