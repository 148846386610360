import { SongInterface } from 'zero-chords-shared';

function SongBodyComponent({ song }: { song: SongInterface }) {
  return (
    <div className="songBodyContainer">
      <div className="songBody">
        {song.body.map((lyric, idx) => {
          return (
            <div className={lyric.chords ? 'chord' : 'lyric'} key={`${song.id}-${idx}`}>
              {lyric.value}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SongBodyComponent;
