import { ListGroup } from 'react-bootstrap';
import { UserLiveInterface } from 'zero-chords-shared';
import * as Icon from 'react-bootstrap-icons';
import { useState } from 'react';
import ConfirmationMenuComponent from '../menu/ConfirmationMenu';

function LiveUserListItemComponent({ user, handleDeleteUser, handleUserClick }: { user: UserLiveInterface; handleDeleteUser?: (userId: string) => void; handleUserClick?: (userId: string) => void }) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  return (
    <>
      <ListGroup.Item as="li" className="d-flex justify-content-between " key={`user-item-idx-${user.id}`}>
        <div className="fw-bold" onClick={() => handleUserClick && handleUserClick(user.liveId)}>
          {user.username} | {user.name}
        </div>
        {handleDeleteUser && (
          <div style={{ minWidth: '20px' }}>
            <Icon.Trash3 min={20} size={20} onClick={() => setShowConfirmation(true)} />
          </div>
        )}
      </ListGroup.Item>
      {showConfirmation && <ConfirmationMenuComponent setShowConfirmation={setShowConfirmation} callBackFn={() => handleDeleteUser && handleDeleteUser(user.id!)} message={'dejar de compartir'} />}
    </>
  );
}

export default LiveUserListItemComponent;
