import { FetchDataInterface } from '../interfaces/FetchDataInterface';

export const fetchDataState = <T>({ data, loading = false, error = false }: FetchDataInterface<T>): FetchDataInterface<T> => {
  return {
    data,
    loading,
    error,
  };
};

export const getAxiosErrorMsg = (err: any) => {
  return err.response?.data?.message ?? 'Ocurrió un error inesperado :(';
};

export const isValidUrl = (value: string) => {
  let url;
  try {
    url = new URL(value);
  } catch {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};
