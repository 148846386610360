import * as Icon from 'react-bootstrap-icons';
import { useState } from 'react';
import QRComponent from './QR';
import ModalMenuComponent from '../menu/ModalMenu';

export function ShareMenuComponent({ shareId, shareType, shareTitle }: { shareId: string; shareType: string; shareTitle: string }) {
  const [displayMenu, setDisplayMenu] = useState(false);

  const getQRLink = () => {
    return `${window.location.origin.toString()}/share/${shareType}/${shareId}`;
  };

  return (
    <div>
      {displayMenu && (
        <ModalMenuComponent setDisplayMenu={setDisplayMenu} extraClassNames="menu-big-font-size">
          <div className="mt-5 mb-5 div-title">{shareTitle}</div>
          <QRComponent link={getQRLink()} />
        </ModalMenuComponent>
      )}
      <Icon.ShareFill size={`1.8em`} className="bottomButton" onClick={() => setDisplayMenu(true)} />
    </div>
  );
}

export default ShareMenuComponent;
