import LiveShareComponent from './LiveShare';
import { LiveInterface } from 'zero-chords-shared';
import { useEffect, useState } from 'react';
import { fetchLive, fetchUpdateLive } from '../../services/LiveService';
import { fetchDataState } from '../../utils';
import LoadingHolderComponent from '../loading/LoadingHolder';
import LiveFollowComponent from './LiveFollow';

function LiveComponent({}: {}) {
  const [liveInfo, setLiveInfo] = useState(fetchDataState<LiveInterface>({ data: null, loading: true }));

  useEffect(() => {
    fetchLive({})
      .then((data) => setLiveInfo(fetchDataState({ data })))
      .catch(() => setLiveInfo(fetchDataState<LiveInterface>({ data: null, error: true })));
  }, []);

  const handleUpdateLiveInfo = (newLiveInfo: LiveInterface) => {
    fetchUpdateLive(newLiveInfo).then((liveData) => setLiveInfo(fetchDataState({ data: liveData })));
  };

  return liveInfo.data ? (
    <>
      <LiveFollowComponent liveInfo={liveInfo.data} />
      <hr />
      <LiveShareComponent liveInfo={liveInfo.data} updateLiveInfo={handleUpdateLiveInfo} />
    </>
  ) : (
    <LoadingHolderComponent fetchData={liveInfo} />
  );
}

export default LiveComponent;
