import { Link, useLocation } from 'react-router-dom';
import MainMenuComponent from './menu/MainMenu';
import { hideOnSongDetail } from '../utils/Navigation.utils';
import { useUser } from '../hooks/UserHook';
import LiveBadgeComponent from './live/LiveBadge';
import * as Icon from 'react-bootstrap-icons';

export function HeaderComponent() {
  const { pathname } = useLocation();
  const user = useUser();

  return (
    <>
      {!hideOnSongDetail(pathname) && (
        <header className="App-header">
          <div className="align-middle">
            <Link to={'/'} style={{ textDecoration: 'none', color: 'white' }}>
              ZeroChords v{process.env.REACT_APP_VERSION}
            </Link>
            {user.userInfo.user && <LiveBadgeComponent />}
            <Icon.CloudDownload onClick={() => window.location.reload()} />
          </div>
          {user.userInfo.user && <MainMenuComponent />}
        </header>
      )}
    </>
  );
}

export default HeaderComponent;
