import { useState } from 'react';
import NewSongStep1Component from './EditSongStep1';
import NewSongStep3Component from './EditSongStep3';
import NewSongStep2Component from './EditSongStep2';
import { KeysEnum, SongInterface } from 'zero-chords-shared';
import SongFlowButtonsComponent from './SongFlowButtons';
import { KeyValueInterface } from '../../../interfaces/MiscInterface';
import { fetchCreateSong } from '../../../services';
import HelperAlertComponent from '../../misc/HelperAlert';
import { getAxiosErrorMsg, isValidUrl } from '../../../utils';

function EditSongComponent() {
  const [newSong, setNewSong] = useState<SongInterface>({
    author: '',
    name: '',
    body: [],
    link: '',
    key: KeysEnum.C,
    tags: [],
  });
  const [currStep, setCurrStep] = useState(1);
  const [helperText, setHelperText] = useState('');
  const [finished, setFinished] = useState(false);

  const confirmHandler = () => {
    fetchCreateSong(newSong)
      .then(() => {
        setHelperText('Canto registrado correctamente, puede ver el status de la verificación en la página inicial.');
        setFinished(true);
      })
      .catch((err) => setHelperText(getAxiosErrorMsg(err)));
  };

  const validateLink = (): boolean => {
    let valid = newSong.link.length === 0 || isValidUrl(newSong.link);
    if (!valid) setHelperText('El link introducido debe ser una URL válida');
    return valid;
  };

  const stepValidator = (): boolean => {
    let valid = false;
    setHelperText('');
    if (currStep === 1) {
      valid = newSong.name.length > 0 && newSong.tags.length > 0;
      if (!valid) setHelperText('El título y al menos una categoría son requeridos');
      valid = valid && validateLink();
    } else if (currStep === 2) {
      valid = newSong.body.length > 0;
      if (!valid) setHelperText('No se han detectado lineas de letra (mínimo 4)');
    } else if (currStep === 3) {
      valid = true;
    }
    return valid;
  };

  const getStepContent = () => {
    const stepContent: KeyValueInterface = {
      1: <NewSongStep1Component newSong={newSong} setNewSong={setNewSong} />,
      2: <NewSongStep2Component newSong={newSong} setNewSong={setNewSong} />,
      3: <NewSongStep3Component newSong={newSong} />,
    };
    return stepContent[currStep];
  };

  const getStepSubTitle = () => {
    const stepTitles: KeyValueInterface = {
      1: 'Información general',
      2: 'Letra y acordes',
      3: 'Confirmar información',
    };
    return stepTitles[currStep];
  };

  const getStepTitles = () => {
    return (
      <>
        <h3>Nuevo Canto (Paso {currStep})</h3>
        <h4>{getStepSubTitle()}</h4>
      </>
    );
  };
  return (
    <div className="m-4">
      {getStepTitles()}
      {getStepContent()}
      <HelperAlertComponent helperText={helperText} />
      {!finished && <SongFlowButtonsComponent currStep={currStep} setCurrStep={setCurrStep} stepValidator={stepValidator} confirmAction={confirmHandler} />}
    </div>
  );
}

export default EditSongComponent;
