import { FILTER_ACTIONS_ENUM } from '../enums/Reducer.enums';
import { SongFiltersInterface } from '../../../shared/src/interfaces';

export const FilterReducer = (state: SongFiltersInterface, action: { type: string; payload?: SongFiltersInterface }) => {
  const { type, payload } = action;

  switch (type) {
    case FILTER_ACTIONS_ENUM.SET_FILTERS: {
      const newState = structuredClone(state);

      if (payload?.category) {
        if (payload.category.flag) newState.categories?.push(payload.category.value);
        else {
          const idx = newState.categories?.lastIndexOf(payload.category.value) ?? -1;
          newState.categories?.splice(idx, 1);
        }
      }

      if (!!payload?.text || payload?.text === '') newState.text = payload.text;

      return newState;
    }
    case FILTER_ACTIONS_ENUM.CLEAN_ALL_FILTERS: {
      return { text: '', categories: [] };
    }
    case FILTER_ACTIONS_ENUM.CLEAN_TAG_FILTERS: {
      return { ...state, categories: [] };
    }
  }

  return state;
};
