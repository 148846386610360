import { useNavigate } from 'react-router-dom';
import { useUser } from '../hooks/UserHook';
import { useEffect } from 'react';

function LogOffComponent() {
  const { logOff } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    logOff();
    navigate('/login');
  }, []);

  return <></>;
}

export default LogOffComponent;
