import { LiveInterface, UserLiveInterface } from 'zero-chords-shared';
import LiveUserListComponent from './LiveUserList';
import { useNavigate } from 'react-router-dom';

function LiveFollowComponent({ liveInfo }: { liveInfo: LiveInterface }) {
  const navigate = useNavigate();
  const handleUserClick = (liveId: string) => {
    navigate(`/live-view/${liveId}`);
  };

  const isFollowing = (liveInfo.following?.length ?? 0) > 0;

  return (
    <div className="d-flex flex-column m-5">
      <h5 className="mt-3">Seguir a{` (${liveInfo.following?.length})`}:</h5>
      {isFollowing ? <LiveUserListComponent users={liveInfo.following as UserLiveInterface[]} handleUserClick={handleUserClick} /> : 'Nadie ha compartido contigo'}
    </div>
  );
}

export default LiveFollowComponent;
