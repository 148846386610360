import { USER_ACTIONS_ENUM } from '../enums/Reducer.enums';
import { UserInfoInterface } from '../interfaces/ReducerInterface';
import { setUserInfoToLocalStorage } from '../utils';

export const UserReducer = (state: UserInfoInterface, action: { type: string; payload: UserInfoInterface }) => {
  const { type, payload } = action;
  let newState = structuredClone(state);

  switch (type) {
    case USER_ACTIONS_ENUM.SET_USER: {
      newState = { ...newState, user: payload.user };
      break;
    }
    case USER_ACTIONS_ENUM.SET_USER_AUTH: {
      newState = { ...newState, userAuth: payload.userAuth };
      break;
    }
  }

  setUserInfoToLocalStorage(newState);

  return newState;
};
