import { Alert } from 'react-bootstrap';

function HelperAlertComponent({ helperText }: { helperText: string }) {
  return (
    <>
      {helperText.length > 0 && (
        <div className="m-1">
          <Alert variant="secondary" className="mt-4">
            {helperText}
          </Alert>
        </div>
      )}
    </>
  );
}

export default HelperAlertComponent;
