import { useReducer } from 'react';
import { FilterReducer } from '../../reducers/FilterReducer';
import { SongFiltersInterface } from '../../../../shared/src/interfaces';
import { FILTER_ACTIONS_ENUM } from '../../enums/Reducer.enums';

export function useFilterReducer() {
  const [state, dispatch] = useReducer(FilterReducer, { categories: [], text: '' });

  const setFilters = (songFilters: SongFiltersInterface) =>
    dispatch({
      type: FILTER_ACTIONS_ENUM.SET_FILTERS,
      payload: songFilters,
    });

  const cleanTagFilters = () =>
    dispatch({
      type: FILTER_ACTIONS_ENUM.CLEAN_TAG_FILTERS,
    });

  const cleanAllFilters = () =>
    dispatch({
      type: FILTER_ACTIONS_ENUM.CLEAN_ALL_FILTERS,
    });

  return { state, setFilters, cleanTagFilters, cleanAllFilters };
}
