import { useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { SchemaInterface, SchemaStructureInterface } from 'zero-chords-shared';
import HelperAlertComponent from '../misc/HelperAlert';

function NewSchemaItemComponent({
  setShowMenu,
  addFolder,
  addSchema,
}: {
  setShowMenu: (value: boolean) => void;
  addFolder: (value: SchemaStructureInterface) => void;
  addSchema: (value: SchemaInterface) => void;
}) {
  const [newFields, setNewFields] = useState({ title: '', description: '' });
  const [lblHelp, setLblHelp] = useState('');
  enum ItemTypesEnum {
    SCHEMA,
    FOLDER,
  }
  const [itemType, setItemType] = useState(ItemTypesEnum.SCHEMA);

  const validateTitle = () => {
    setLblHelp('');
    if (!newFields.title.trim().length) {
      setLblHelp('El título es obligatorio');
      return false;
    }
    return true;
  };
  const addNewSchema = () => {
    if (validateTitle()) {
      const newSchema: SchemaInterface = { title: newFields.title, description: newFields.description, songs: [] };
      addSchema(newSchema);
      setShowMenu(false);
    }
  };

  const addNewFolder = () => {
    if (validateTitle()) {
      const newFolder = { path: newFields.title, schemas: [], directories: [] };
      addFolder(newFolder);
      setShowMenu(false);
    }
  };

  return (
    <div className="m-5">
      <h2 className="d-flex mb-4 justify-content-center textGray">Crear nuev{itemType === ItemTypesEnum.SCHEMA ? 'o Esquema' : 'a Carpeta'}</h2>
      <Form className="d-flex flex-column justify-content-center">
        <FloatingLabel controlId="floatingTextarea" label="Título" className="mb-3">
          <Form.Control type="text" placeholder="Título del esquema" onChange={(e) => setNewFields({ ...newFields, title: e.currentTarget.value })} maxLength={32} required />
        </FloatingLabel>
        {itemType === ItemTypesEnum.SCHEMA ? (
          <FloatingLabel controlId="floatingTextarea" label="Descripción" className="mb-3">
            <Form.Control type="text" placeholder="Descripción del esquema" onChange={(e) => setNewFields({ ...newFields, description: e.currentTarget.value })} maxLength={32} required />
          </FloatingLabel>
        ) : (
          ''
        )}

        <Button className="mt-4" variant="danger" type="button" onClick={() => (itemType === ItemTypesEnum.SCHEMA ? addNewSchema() : addNewFolder())}>
          Agregar
        </Button>
        <Button className="mt-3" variant="secondary" onClick={() => setItemType(itemType === ItemTypesEnum.SCHEMA ? ItemTypesEnum.FOLDER : ItemTypesEnum.SCHEMA)}>
          Cambiar a {itemType === ItemTypesEnum.SCHEMA ? 'carpeta' : 'esquema'}
        </Button>
        <HelperAlertComponent helperText={lblHelp} />
      </Form>
    </div>
  );
}

export default NewSchemaItemComponent;
