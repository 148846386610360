import { useEffect, useState } from 'react';
import { SongInterface } from 'zero-chords-shared';
import { SongMenuComponent } from './SongMenu';
import { useSongs } from '../../hooks/SongHook';
import { useLive } from '../../hooks/LiveHook';
import SongBodyComponent from './SongBody';
import ModalMenuComponent from '../menu/ModalMenu';
import SongInfoComponent from './SongInfo';
import LineBreakComponent from '../misc/LineBreak';

function SongComponent({ loadedSong, prevNextCallBack }: { loadedSong: SongInterface; prevNextCallBack?: (direction: number) => void }) {
  const [displayModalInfo, setDisplayModalInfo] = useState(false);
  const [song, setSong] = useState(loadedSong);
  const { liveMode, updateLiveSong } = useLive();
  const { transposeBody } = useSongs();

  const transposeHandler = (direction: number) => {
    const newSong = transposeBody(song, direction);
    setSong(newSong);
  };

  useEffect(() => {
    setSong(loadedSong);
    console.log(liveMode);
    if (liveMode.active) {
      updateLiveSong(song.id!);
    }
  }, [loadedSong]);

  return (
    <>
      {song && (
        <>
          <div className="songHolder">
            <div className="song">
              <div className="songTitle" onClick={() => setDisplayModalInfo(true)}>
                {song.name.toUpperCase()}
              </div>
              <SongBodyComponent song={song} />
              <br />
            </div>
            <SongMenuComponent transposeCallback={transposeHandler} songId={song.id!} prevNextCallBack={prevNextCallBack} />
          </div>
          {displayModalInfo && (
            <ModalMenuComponent setDisplayMenu={setDisplayModalInfo}>
              <div className="d-flex text-white justify-content-center">
                <SongInfoComponent song={song} />
              </div>
            </ModalMenuComponent>
          )}
          <LineBreakComponent count={3} />
        </>
      )}
    </>
  );
}

export default SongComponent;
