import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useUser } from '../../hooks/UserHook';
import * as Icon from 'react-bootstrap-icons';
import { getAxiosErrorMsg } from '../../utils';
import HelperAlertComponent from '../misc/HelperAlert';

function LoginComponent() {
  const [userInfo, setUserInfo] = useState<{ username: string; password: string }>({ username: process.env.REACT_APP_DEFAULT_USER ?? '', password: process.env.REACT_APP_DEFAULT_PASSWORD ?? '' });
  const [validated, setValidated] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const user = useUser();

  useEffect(() => {
    if (validated) {
      user
        .authenticate(userInfo)
        .catch((err) => {
          setHelperText(getAxiosErrorMsg(err));
        })
        .finally(() => {
          setValidated(false);
        });
    }
  }, [validated]);

  const handleSubmit = (event: any) => {
    setHelperText('');
    const form = event.currentTarget;
    event.preventDefault();
    if (!form.checkValidity()) {
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <div className="homeHolder d-flex flex-column m-5">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Usuario</Form.Label>
          <Form.Control type="user" placeholder="Escribe tu usuario" onChange={(e) => setUserInfo({ ...userInfo, username: e.target.value })} value={userInfo.username} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Contraseña</Form.Label>
          <div className="d-flex mx-auto">
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              placeholder="Escribe tu contraseña"
              onChange={(e) => setUserInfo({ ...userInfo, password: e.target.value })}
              value={userInfo.password}
              required
            />
            <div className="align-middle m-2">
              <Icon.Eye onClick={() => setShowPassword(!showPassword)} />
            </div>
          </div>
        </Form.Group>
        <br />
        <HelperAlertComponent helperText={helperText} />
        <br />
        <Button variant="secondary" type="submit">
          Login
        </Button>
      </Form>
    </div>
  );
}

export default LoginComponent;
