import { Form, ProgressBar } from 'react-bootstrap';
import SongCategoriesAccordion from '../categories/SongCategoriesAccordion';
import { SongInterface, TagInterface } from 'zero-chords-shared';
import RequiredFieldComponent from '../../misc/RequiredField';

function EditSongStep1Component({ newSong, setNewSong }: { newSong: SongInterface; setNewSong: (newSong: SongInterface) => void }) {
  const handleTagClick = (tag: TagInterface, status: boolean) => {
    let newTags: string[];
    if (status) {
      newTags = Array.from(new Set([...newSong.tags, tag.value]));
    } else {
      newTags = newSong.tags.filter((t) => t !== tag.value);
    }
    setNewSong({ ...newSong, tags: newTags });
  };

  return (
    <div className="m-4">
      <ProgressBar now={10} variant="danger" className="mt-3" />
      <Form.Group className="mt-3" controlId="formNewSong">
        <h6>
          Título <RequiredFieldComponent />
        </h6>
        <Form.Control
          type="text"
          placeholder="Introduce el título del canto"
          onChange={(e) => {
            setNewSong({ ...newSong, name: e.target.value });
          }}
          value={newSong.name}
          maxLength={32}
          required
        />
      </Form.Group>
      <Form.Group className="mt-3" controlId="formNewSong">
        <h6>Autor</h6>
        <Form.Control
          type="text"
          placeholder="Introduce el nombre del autor"
          onChange={(e) => {
            setNewSong({ ...newSong, author: e.target.value });
          }}
          value={newSong.author}
          maxLength={32}
          required
        />
      </Form.Group>
      <Form.Group className="mt-3" controlId="formNewSong">
        <h6>
          Categorías <RequiredFieldComponent />
        </h6>
        <SongCategoriesAccordion song={newSong} clickOnTag={handleTagClick} />
      </Form.Group>
      <Form.Group className="mt-3" controlId="formNewSong">
        <h6>Link de referencia</h6>
        <Form.Control
          type="text"
          placeholder="Introduce un link de referencia"
          onChange={(e) => {
            setNewSong({ ...newSong, link: e.target.value });
          }}
          value={newSong.link}
          maxLength={256}
          required
        />
      </Form.Group>
    </div>
  );
}

export default EditSongStep1Component;
