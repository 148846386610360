import { Form } from 'react-bootstrap';
import { SongInterface } from 'zero-chords-shared';
import SongCategoriesAccordion from './categories/SongCategoriesAccordion';

function SongInfoComponent({ song, showUser = false }: { song: SongInterface; showUser?: boolean }) {
  return (
    <Form.Group className="mt-4" controlId="formNewSong">
      <h6>Título:</h6>
      <h5>
        <b>{song.name}</b>
      </h5>
      <h6>Autor:</h6>
      <h5>
        <b>{song.author.length > 0 ? song.author : 'Desconocido'}</b>
      </h5>
      <h6>Tono base:</h6>
      <h5>
        <b>{song.key}</b>
      </h5>
      {song.link.length > 0 && (
        <>
          <h6>Link de referencia: </h6>
          <h5>
            <a href={song.link} target="_blank">
              {song.link}
            </a>
          </h5>
        </>
      )}
      {showUser && (
        <>
          <h6>Usuario:</h6>
          <h5>
            <b>
              {song.owner?.username} | {song.owner?.name}
            </b>
          </h5>
        </>
      )}
      <SongCategoriesAccordion song={song} />
    </Form.Group>
  );
}

export default SongInfoComponent;
