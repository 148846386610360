import { LiveReducerInterface } from '../interfaces/ReducerInterface';

const DEFAULT_VALUE = { active: false };
export const getLiveInfoFromLocalStorage = (): LiveReducerInterface | {} => {
  const storageVal = localStorage.getItem('liveInfo');
  if (!storageVal?.length) return DEFAULT_VALUE;
  try {
    return JSON.parse(storageVal);
  } catch {
    return DEFAULT_VALUE;
  }
};

export const setLiveInfoToLocalStorage = (userInfo: LiveReducerInterface) => {
  localStorage.setItem('liveInfo', JSON.stringify(userInfo));
};
