import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import ShareMenuComponent from '../misc/ShareMenu';
import { useNavigate } from 'react-router-dom';

export function SongMenuComponent({ transposeCallback, songId, prevNextCallBack }: { transposeCallback: (direction: number) => void; songId: string; prevNextCallBack?: (direction: number) => void }) {
  const [showTranspose, setShowTranspose] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="bottomButtonHolder gap-3">
      {prevNextCallBack && (
        <div className="d-flex m-2 " style={{ alignItems: 'center' }} onClick={() => prevNextCallBack(-1)}>
          <Icon.ArrowLeftCircleFill size={`1.8em`} />
        </div>
      )}
      <div className="d-flex m-2 " style={{ alignItems: 'center' }} onClick={() => navigate(-1)}>
        <Icon.ArrowReturnLeft size={`1.8em`} />
      </div>
      {showTranspose && <Icon.ArrowDownCircleFill size={`1.8em`} onClick={() => transposeCallback(-1)} className="bottomButton" />}
      <div className="d-flex" style={{ alignItems: 'center' }} onClick={() => setShowTranspose(!showTranspose)}>
        <Icon.ArrowDownUp size={`1.8em`} />
      </div>
      {showTranspose && <Icon.ArrowUpCircleFill size={`1.8em`} onClick={() => transposeCallback(1)} className="bottomButton" />}
      <ShareMenuComponent shareTitle="Compartir canto" shareId={songId} shareType="song" />
      {prevNextCallBack && (
        <div className="d-flex m-2 " style={{ alignItems: 'center' }} onClick={() => prevNextCallBack(1)}>
          <Icon.ArrowRightCircleFill size={`1.8em`} />
        </div>
      )}
    </div>
  );
}

export default SongMenuComponent;
